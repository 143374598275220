/*
 Theme Name: Coldwell Banker Blue and White
 Version: 3
 Author: Alok Jain
 Last edited: Alok Jain - May 27, 2024
*/

/*************************************
 *spec the layout divisions
 *************************************/
body {
	background-color: var(--content-inverted);
	color: var(--content-default);
	font-size: var(--content-font-size);
	margin: 0px;
	font-family: var(--content-font);
	font-weight: 400;
	line-height: 1.4;
	overflow-x: hidden;
}

table {
	width: 100%;
	margin: 0 auto;
	color: var(--content-default);
}

h1, h2, h3, h4, h5, h6,
.menu-title, .subdivision-list-title, .condominium-list-title,
.fplist-title, .search-title, .featured_listings_header, .detail-image-title,
.detail-streetview-title, .detail-features-title, .detail-comment-title,
.detail-mapview-title, .detail-contact-title,
.detail-social-title, .subhead_agentlist,.testimonial_subtitle,
.agent_listings_header, .notice .subhead, .mtg_title, .liked-list-title,
.refine_search .notice_title, .notice_title, .refine-title,
.accessibility-statement-title {
	font-family: var(--heading-font);
	font-size: var(--h1-font-size);
	color: var(--heading-color);
	box-sizing: border-box;
	margin-bottom: 10px;
	margin-top: 0;
}

.agent-testimonial-title,
.detail-features-nearsubs,
.map-title,
.board_title,
.comment_title,
.accessibility-statement-subtitle {
	font-family: var(--heading-font);
	font-size: var(--h2-font-size);
	color: var(--heading-color);
	margin-bottom: 10px;
	font-weight: bold;
}

.sd_addressbar, .prop .column-title {
	color: var(--heading-color);
	font-family: var(--heading-font);
}

a {
	color: var(--content-link-color);
	text-decoration: none;
}

a:hover {
	color: var(--content-link-hover);
}

.container {
	width: 1280px;
	margin: 0 auto;
	padding: 0 20px;
	box-sizing: border-box;
}

main .container {
	overflow: hidden;
}

p {
	margin-top: 0;
	margin-bottom: 20px;
}

.pageholder {
	margin-top: 30px;
	position: relative;
	flex: 1;
}

.contactform {
	margin: 30px 0;
	overflow: hidden;
	position: relative;
	display: flex;
	gap: 30px;
	justify-content: space-between;
}

.promo-contact-form {
	margin: 0;
}

.contactform .propholder {
	flex: 1;
	margin-bottom: 0;
}

/*************************************
 * Form Elements
 *************************************/
label {
	font-weight: 600;
}

input[type="text"], input[type="email"], input[type="number"], input[type="tel"], input[type="password"] {
	height: 40px;
	border: none;
	border-radius: 5px;
	background: #fff;
	font-size: var(--content-font-size);
	color: #666;
	padding: 10px;
	box-sizing: border-box;
	border: 1px solid var(--border);
}

textarea {
	border-radius: 5px;
}

select {
	padding: 0.375rem 2.25rem 0.375rem 0.75rem;
	height: 40px;
	font-size: var(--content-font-size);
	line-height: 1.5;
	font-family: inherit;
	color: #666;
	background-color: #fff;
	background-image: url("../images/down-arrow.svg");
	background-repeat: no-repeat;
	background-position: right 0.75rem center;
	background-size: 12px 8px;
	border: 1px solid var(--border);
	border-radius:5px;
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
	width: 100%;
}

select:focus {
	border-color: #86b7fe;
	outline: 0;
	box-shadow: 0 0 0 0.25rem rgb(13 110 253 / 25%);
}

select[multiple],
select[size]:not([size="1"]) {
	background-image: none;
	padding-right: 0.75rem;
}

.promo_form, #contact_form, .the_contact_form,
.the_contact_form.pop-up.contact_form,
.contact_form, .detailform.pop-up, .detail-submit-offer-popup {
	background-color: var(--content-default);
	color: var(--content-inverted);
	border-radius: 5px;
	box-shadow: 0 0 4px rgba(0, 0, 0, 16%);
	font-family: var(--content-font);
}

.the_contact_form.contact_form li, .blog #commentform li {
	margin-bottom: 20px;
}

/*************************************
 * Headings
 *************************************/
.fplist-title,
.map-frontpage h2,
.subdivision-list-title,
.condominium-list-title,
.testimonials-list-title,
.sec-title,
.page-title,
.agent-testimonial-title,
.agent_listings_header,
.comment_title,
.mtg_title,
.detail-comment-title,
.subhead_agentlist {
	position: relative;
	padding-bottom: 10px;
	margin-bottom: 30px;
}

.subhead_agentlist,
.board_title {
	display: inline-block;
}

.social-title,
.detail-features-nearsubs,
.map-title,
.board_title {
	color: var(--heading-color);
	font-family: var(--heading-font);
	font-size: var(--h2-font-size);
	position: relative;
	padding-bottom: 10px;
	margin-bottom: 15px;
	text-align: left;
}

.widget .widget-title,
.widget > .widget-inner .widget-subtitle,
.testimonial .testimonial_title {
	font-family: var(--heading-font);
}

.fplist-title::before,
.map-frontpage h2::before,
.subdivision-list-title::before,
.condominium-list-title::before,
.testimonials-list-title::before,
.sec-title::before,
.page-title::before,
.agent-testimonial-title::before,
.agent_listings_header::before,
.social-title::before,
.comment_title::before,
.mtg_title::before,
.detail-features-nearsubs::before,
.map-title::before,
.detail-comment-title::before,
.subhead_agentlist::before,
.board_title::before,
.accessibility-statement-title::before,
.accessibility-statement-subtitle::before {
	content: '';
	position: absolute;
	left: 0;
	bottom: 0;
	width: 100px;
	height: 2px;
	background-color: var(--content-highlight);
}

/*************************************
 * Buttons
 *************************************/
.promo_form.contact_form input[type="submit"].submit,
#contact_form.contact_form input[type="submit"].submit,
.the_contact_form.contact_form input[type="submit"].submit,
.contact_form input[type="submit"].submit,
.the_contact_form.pop-up input[type="submit"].submit,
.detailform.pop-up input[type="submit"].submit,
.the_contact_form input[type="submit"].submit,
.the_search,
input[type="submit"],
.page_links_submit,
.testimonials-page-request .testimonial-form-link,
.delete-button,
.full_listing,
.view_this_home,
.showing_popup,
.btn,
.btn_align_right .btn-success,
.fpmorelink a,
.save-search a,
.sb_poll_rate,
.customer-dashboard-anchor a {
	background-color: var(--content-button-bg);
	font-weight: normal;
	color: var(--content-button-text);
	border: none;
	border-radius: 5px;
	height: 40px;
	padding: 10px 15px;
	box-sizing: border-box;
	line-height: 1;
	transition: all 0.2s ease-in-out;
	font-weight: 600;
	font-family: var(--content-font);
}

.promo_form.contact_form input[type="submit"].submit:hover,
#contact_form.contact_form input[type="submit"].submit:hover,
.the_contact_form.contact_form input[type="submit"].submit:hover,
.contact_form input[type="submit"].submit:hover,
.the_contact_form.pop-up input[type="submit"].submit:hover,
.detailform.pop-up input[type="submit"].submit:hover,
.the_contact_form input[type="submit"].submit:hover,
.the_search:hover,
input[type="submit"]:hover,
.page_links_submit:hover,
.testimonials-page-request .testimonial-form-link:hover,
.delete-button:hover,
.full_listing:hover,
.view_this_home:hover,
.showing_popup:hover,
.btn:hover,
.btn_align_right .btn-success:hover,
.fpmorelink a:hover,
.save-search a:hover,
.sb_poll_rate:hover,
.customer-dashboard-anchor a:hover {
	background-color: var(--content-button-hover-bg);
	color: var(--content-button-hover-text);
}

.delete-button {
	display: inline;
}

.save-search a {
	margin: 30px 0 20px 0;
}

.save-search a, .save-search a:hover {
	display: inline-block;
	line-height: 1.25;
}

.social_link .fbLogin {
	background: url("../images/facebook-button.svg") no-repeat;
	height: 50px;
	width: 340px;
}

/*************************************
 * Pre Header
 *************************************/
.pre-header {
	border-bottom: 1px solid var(--border);
	padding-bottom: 20px;
	margin-bottom: 20px;
}

.pre-header .container {
	display: flex;
	align-items: center;
	justify-content: flex-end;
}

.pre-header ol {
	display: flex;
}

header .agentheader li.agent-email {
	background: url(../images/icon-mail-dark.svg);
	background-repeat: no-repeat;
	background-position: left center;
	padding-left: 27px;
	margin: 5px 0 5px 30px;
}

header .agentheader li.agent-phone.agent-email {
	background: url(../images/icon-phone-dark.svg) no-repeat left center;
}

header .agentheader ul.social-list i,
header .social-list li span {
	display: none;
}

header .social-list {
	padding: 0;
	margin: 0 0 0 45px;
	height: 25px;
}

header .social-list li a {
	width: 25px;
	height: 25px;
	display: block;
}

header .social-list li a.social-Facebook {
	background: var(--icon-facebook-dark) no-repeat 0 0;
}

header .social-list li a.social-Twitter {
	background: var(--icon-twitter-dark) no-repeat 0 0;
}

header .social-list li a.social-Bluesky {
	background: var(--icon-bluesky-dark) no-repeat 0 0;
}
header .social-list li a.social-Linktree {
	background: var(--icon-linktree-dark) no-repeat 0 0;
}

header .social-list li a.social-Share {
	background: var(--icon-share-dark) no-repeat 0 0;
}

/*************************************
 * Header
 *************************************/
header {
	padding: 20px 0;
	border-bottom: 1px solid var(--border);
}

/*************************************
 * Information Container
 *************************************/
.information-container {
	 border-top: 1px solid var(--border);
	 overflow: hidden;
	 width: 100%;
	 padding-top: 20px;
}

.information-container ol {
	margin: 0 auto !important;
	gap: 20px;
	display: flex;
	padding: 0 20px;
	align-items: center;
}

.information-container ol li {
	margin-right: 35px;
}

header .brokerage,
header .agentname {
	font-family: var(--heading-font);
	font-size: var(--h2-font-size);
	font-weight: bold;
	color: var(--heading-color);
}

/*************************************
 * Navigation
 *************************************/
#menu_section {
	margin-bottom: 20px;
}

#menu_section .container {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.agentheader img {
    max-height: 100px;
}

.nav {
	border-top: 1px solid var(--border);
	border-bottom: 1px solid var(--border);
	height: 60px;
}

.nav-sec {
	display: none;
}

.nav-list {
	list-style: none;
	text-align: center;
	padding: 0;
	margin: 0;
}

.nav-list li {
	display: inline-block;
	padding: 20px;
	position: relative;
	font-weight: 600;
}

.nav-list li.nav-wrap > a,
.nav-list a.group-nav-container {
	position: relative;
	padding-right: 16px;
}

.nav-list li.nav-wrap > a::after,
.nav-list a.group-nav-container::after {
	content: '';
	position: absolute;
	top: 7px;
	right: 0;
	background: url(../images/down-arrow.svg) no-repeat;
	width: 11px;
	height: 6px;
}

.nav-list li ul {
	display: none;
}

.nav-list li:hover > ul {
	display: block;
	position: absolute;
	left: 0;
	top: 60px;
	z-index: 3;
	background-color: var(--content-light-bg);
	padding: 0 0 15px 0;
	margin: 0;
	width: 200px;
	border-top: 2px solid var(--content-highlight);
}

.subnav li,
.nav-wrap li {
	padding: 15px 15px 0 15px;
	text-align: left;
	display: block;
}

.nav-wrap ul {
	display: none;
}

.nav-wrap li:hover > ul {
	display: block;
	left: -100%;
	top: 0;
}

/*************************************
 * Banner
 *************************************/
#banner {
	margin-bottom: 30px;
}

.banner_wrapper_video {
    overflow: hidden;
}

.homepage-banner-wrap {
	height: 400px;
	position: relative;
}

.homepage-banner-wrap:after {
	content: '';
	position: absolute;
	width: 100%;
	height: 100%;
	background: rgba(0, 0, 0, 0.4);
	z-index: 1;
}

#homepage-banner #banner-slider-wrap {
	overflow: hidden;
}

.banner-container {
	position: absolute;
	z-index: 2;
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}

.banner-heading {
	text-align: center;
}

.banner-heading h1 {
	font-size: var(--banner-heading-font-size);
	color: var(--banner-heading-color);
	line-height: 1;
}

.banner-heading p {
	font-size: var(--banner-subheading-font-size);
	color: var(--banner-subheading-color);
	font-weight: 600;
}

.banner-menu {
	text-align: center;
	max-width: 725px;
	width: 100%;
}

.banner-menu-items a {
	color: var(--banner-link-color);
	border-bottom: 2px solid var(--content-highlight);
	font-weight: 600;
	margin: 20px 15px;
	display: inline-block;
}

.banner-searchbar {
	border: 5px solid var(--content-inverted);
	display: block;
	position: relative;
}

.banner-searchbar form {
	display: flex;
	width: 100%;
}

.banner-menu input[type="search"] {
	height: 50px;
	background: var(--content-light-bg);
	border: none;
	flex: 1;
	color: var(--content-highlight);
}

.banner-menu input[type="search"]:focus {
	border: none !important;
	box-shadow: none;
}

.banner-searchbar-button {
	background-color: var(--content-button-bg);
	background-image: url(../images/icon-loupe-white.svg);
	background-repeat: no-repeat;
	background-position: center;
	color: var(--content-button-text);
	border: none;
	box-shadow: none;
	height: 50px;
	width: 50px;
	padding: 0 10px;
	font-size: 1.8rem;
	text-indent: -999px;
	overflow: hidden;
}

.banner-searchbar-button:hover {
	background-color: var(--content-button-hover-bg);
	color: var(--content-button-hover-text);
}

.home-page-list-group {
	position: absolute;
	top: 55px;
	left: -8px;
	width: calc(100% + 10px);
	box-sizing: border-box;
}

.home-page-list-group .home-page-list-group-item {
	border-radius: 0;
	padding: 12px 20px;
}

.home-page-list-group .home-page-list-group-item:hover {
	background: var(--content-light-bg);
}

.home-page-list-group .home-page-list-group-item:hover a {
	color: var(--content-highlight);
}

/*************************************
 * Breadcrumb
 *************************************/
.breadcrumb {
	margin: 20px 0 10px;
	font-weight: 600;
}

.breadcrumb-list li {
	margin-right: 10px;
	position: relative;
	padding-right: 15px;
}

.breadcrumb-list li::after {
	content: '>';
	position: absolute;
	top: 0;
	right: 0;
}

.breadcrumb-list li:last-child:after {
	display: none;
}

.breadcrumb-list li a {
	color: var(--content-link-color);
}

.breadcrumb-list li a:hover {
	color: var(--content-default);
}

/*************************************
 * Content Section
*************************************/
#maincontent {
	position: relative;
}

/*************************************
 * Notice
*************************************/
.notice {
	margin-bottom: 40px;
	background-color: var(--grey-bg);
	padding: 10px;
	border-radius: 5px;
	border-top: 2px solid var(--content-highlight);
}

.notice-title {
	font-weight: bold;
	margin-bottom: 10px;
	color: var(--content-highlight);
}

.pagebody {
	margin: 30px 0;
}

.map-frontpage {
	margin-bottom: 60px;
}

#open_map {
	border-radius: 5px;
	box-shadow: 0px 0px 4px rgba(0, 0, 0, 20%);
	z-index: 1;
}

/*************************************
 * Login Form
 *************************************/
#fb_login_form {
	background-color: var(--content-default);
	color: var(--content-inverted);
	border-radius: 5px;
	box-shadow: 0 0 4px rgba(0, 0, 0, 16%);
	padding: 30px;
}

#fb_login_form li,
#fb_login_form form {
	margin-bottom: 20px;
}

#fb_login_form a {
	color: var(--content-inverted);
}

.login_seperator {
	margin: 5px 0px 30px;
}

.login_seperator:before, .login_seperator:after {
	border-top: 1px solid var(--content-inverted);
}

/*************************************
 * Pre Footer (Subdivisons / Condominiums / Testimonial)
 *************************************/
 .pre-footer {
	background-color: var(--content-default);
	color: var(--content-inverted);
	padding: 40px 0;
}

.pre-footer .container {
	display: flex;
	min-height: 300px;
}

.subdivision-list-title,
.condominium-list-title,
.testimonials-list-title {
	color: var(--content-inverted);
}

.subdivisionlist ol {
	border-top: 1px solid var(--grey-light);
	width: 80%;
}

.subdivisionlist li {
	border-bottom: 1px solid var(--grey-light);
}

.subdivisionlist li a {
	background-image: url(../images/icon-ellipse.svg), url(../images/chevron-right-solid.svg);
    background-repeat: no-repeat, no-repeat;
    background-position: left center, 95% center;
	display: block;
	font-weight: bold;
	font-size: 1.8rem;
	line-height: 1;
	padding: 12px 30px 12px 30px;
}

.pre-footer .one-third {
	flex: 0 0 33%;
}

.pre-footer .one-fourth {
	flex: 0 0 25%;
}

.pre-footer a {
	color: var(--content-inverted);
}

.testimonials-list-title {
	background: url("../images/icon-quote.svg") no-repeat 145px 6px;
	padding-right: 30px;
}

#home-testimonials .testimonial {
	float: left;
	width: 400px;
	border: none;
	padding: 0;
}

.testimonial .testimonial_title,
.testimonial_date {
	text-align: left;
}

#home-testimonials .testimonial_title,
#home-testimonials .testimonial_name,
#home-testimonials a {
	color: var(--content-inverted);
}

.testimonial-pg {
	display: block;
	border-bottom: 1px solid var(--grey-light);
	border-top: 1px solid var(--grey-light);
	padding: 12px 30px 12px 30px;
	background-image: url(../images/icon-ellipse.svg), url(../images/chevron-right-solid.svg);
	background-repeat: no-repeat, no-repeat;
	background-position: left center, 95% center;
	display: block;
	font-weight: bold;
	font-size: 1.8rem;
	line-height: 1;
}

/*************************************
 * Featured Listings
 *************************************/
.featuredListing-wrap {
	padding: 60px 0;
}

.featured_listings_header {
	display: none;
}

.featuredprop img {
	width: 270px;
	height: 230px;
	object-fit: cover;
	padding: 5px 5px 3px;
	box-sizing: border-box;
}

 .featuredListing-wrap .featuredprop a {
	 text-decoration: none;
	 text-align: left;
	 background: url(../images/featured-img-bg.svg) no-repeat 0 0;
	 background-size: contain;
	 display: block;
	 width: 270px;
 }

 .featuredListing-wrap .featured_footer {
	 margin-top: 5px;
 }

 .featuredListing-wrap .featured_footer .featured-address-display {
	 padding-left: 20px;
	 margin-left: 5px;
	 background: url(../images/map-marker-alt-solid.svg) no-repeat 0 2px;
	 font-weight: 600;
 }

 .featured_listings .owl-item {
	 float: left;
 }

 .featured_listings .owl-stage-outer {
	 overflow: hidden;
 }

 .featured_listings .disabled {
	 display: none;
 }

 .featured_listings .owl-dot {
	background: var(--content-default);
	width: 16px;
	height: 16px;
	border-radius: 50%;
	border: 2px solid var(--content-highlight);
	margin: 0 5px;
 }

 .featured_listings .owl-dot.active {
	background: var(--content-inverted);
 }

/*************************************
 * Footer
 *************************************/
footer {
	background-color: var(--content-light-bg);
	font-size: 1.4rem;
	line-height: 1.29;
}

footer .container {
	display: flex;
}

footer a {
	text-decoration: none;
}

.footer-agentinfo-block {
	width: 410px;
	background-color: var(--content-default);
	color: var(--content-inverted);
	padding: 40px 20px;
	box-sizing: border-box;
}

.footer-agentinfo-block a {
	color: var(--content-inverted);
}

.footer {
	padding: 40px 0px;
}

footer .spacer {
	padding-left: 40px;
}

.footercopy > div {
	margin-bottom: 15px;
}

.footer a {
	color: var(--content-link-color);
}

.footer a:hover {
	color: var(--content-link-hover);
}

.footer-agentinfo-block .agentheader ol {
	margin-left: 0px !important;
}

.footer-agentinfo-block li {
	margin-bottom: 15px;
	word-wrap: break-word;
}

.footer-agentinfo-block .agentheader img {
	margin-bottom: 15px;
}

.footer-agentinfo-block .brokerage,
.footer-agentinfo-block .agentname {
	font-family: var(--heading-font);
	font-size: var(--h2-font-size);
	font-weight: bold;
}

.footer-agentinfo-block .agentheader li.agent-email {
	background: url(../images/icon-mail-white.svg);
	background-repeat: no-repeat;
	background-position: 0 3px;
	padding-left: 27px;
}

.footer-agentinfo-block .agentheader li.agent-phone.agent-email {
	background: url(../images/icon-phone-white.svg) no-repeat left center;
}

.footer-agentinfo-block .agentheader ul.social-list i,
.footer-agentinfo-block .social-list li span {
	display: none;
}

.footer-agentinfo-block .social-list {
	padding-top: 10px;
}

.agentheader ul.social-list li {
	margin-right: 12px;
}

.footer-agentinfo-block .social-list li a {
	width: 25px;
	height: 25px;
	display: block;
}

.footer-agentinfo-block .social-list li a.social-Facebook {
	background: url(../images/facebook-white.svg) no-repeat 0 0;
}

.footer-agentinfo-block .social-list li a.social-Twitter {
	background: url(../images/twitter-white.svg) no-repeat 0 0;
}

.footer-agentinfo-block .social-list li a.social-Bluesky {
	background: url(../images/bluesky-white.svg) no-repeat 0 0;
}

.footer-agentinfo-block .social-list li a.social-Linktree {
	background: url(../images/linktree-white.svg) no-repeat 0 0;
}

.footer-agentinfo-block .social-list li a.social-Share {
	background: url(../images/share-white.svg) no-repeat 0 0;
}

.footercopy {
	margin-bottom: 10px;
	border-bottom: 1px solid var(--border);
}

.brokeridxlink {
	text-align: left;
	margin-bottom: 0;
	float: left;
}

.brokeridxlink img {
	margin-right: 50px;
}

.brokeridxlink a {
	display: flex;
	margin-right: 50px;
}

.brokeridx_textlink,
.switch-to-mobile-container {
	margin: 18px 0;
}

.switch-to-mobile-container {
	text-align: left;
	overflow: hidden;
	width: auto;
	margin-bottom: 0;
	float: left;
}

/*************************************
 * Testimonial Page
 *************************************/
.testimonials-page-request {
	display: flex;
	align-items: center;
	margin-top: 30px;
    margin-bottom: 30px;
    background-color: var(--grey-bg);
    padding: 10px 15px;
    border-radius: 5px;
    border-top: 2px solid var(--content-highlight);
}

.testimonials-page-request .testimonial-form-link {
	line-height: 1.4;
}

.testimonial, .blog {
	padding: 10px;
    border-radius: 5px;
	border: 1px solid var(--grey-bg);
    border-bottom: 2px solid var(--border);
	box-sizing: border-box;
}

.testimonial_title a {
	color: var(--heading-color);
	font-weight: 600;
}

.testimonial_title a:hover{
	color: var(--content-link-hover);
}

.testimonial .testimonial_name {
	color: var(--content-link-color);
}

.testimonial_body {
	margin-top: 15px;
	font-size: var(--content-font-size);
	padding: 0;
}

.testimonial_form {
	margin: 0 0 60px 0;
	background-color: var(--content-default);
	color: var(--content-inverted);
	padding: 30px;
	border-radius: 5px;
	box-shadow: 0 0 4px rgba(0, 0, 0, 16%);
}

.testimonial_form .page-title {
	color: var(--content-inverted);
}

.testimonial_form li {
	margin-bottom: 20px;
}

.testimonial_form li:last-child {
	margin-bottom: 0;
}

.testimonial_form input[type="text"],
.testimonial_form input[type="email"],
.testimonial_form input[type="tel"] {
	height: 40px;
}

.testimonial_form .highlight.testimonial_body {
	font-family: var(--content-font);
	font-size: var(--content-font-size);
}

/*************************************
 * Blog
 *************************************/
.blog-title {
	color: var(--heading-color);
	font-family: var(--heading-font);
	font-size: var(--h1-font-size);
}

 .blog a {
	font-weight: 600;
}

.blog:nth-child(even) {
	background-color: var(--grey-bg);
  }

.blog-date {
    font-style: italic;
    font-size: small;
    margin-bottom: 9px;
}

.blog-body b {
	font-weight: 400;
	display: inline-block;
	padding: 0 3px;
}

.blog .blog {
	padding: 0px;
    border-radius: 0;
	border: 0px;
}

.blog .blog-agent {
	margin-bottom: 15px;
	font-size: 1.4rem;
}

.blog .social {
	padding: 15px 0;
	text-align: left;
	margin: 0;
}

.blog .social-item img {
	display: none;
}

.blog .social-item a {
	width: 25px;
	height: 25px;
	display: block;
}

.blog .social-item.social-facebook a {
	background: var(--icon-facebook-dark) no-repeat 0 0;
}

.blog .social-item.social-twitter a {
	background: var(--icon-twitter-dark) no-repeat 0 0;
}

.blog .social-item.social-custom-share a {
	background: var(--icon-share-dark) no-repeat 0 0;
}

.fb_iframe_widget_fluid_desktop iframe {
    min-width: 100% !important;
}

/*************************************
 * Quick search
 *************************************/
.quick-search-btn {
	position: absolute;
    top: 95px;
    right: -45px;
    background-color: var(--content-button-bg);
    color: var(--content-button-text);
    border-radius: 5px 5px 0 0;
    display: block;
    transform: rotate(-90deg);
    padding: 9px 15px;
    font-weight: 600;
	cursor: pointer;
	z-index: 3;
}

.quick-search-btn:hover {
	background-color: var(--content-button-hover-bg);
	color: var(--content-button-hover-text);
}

.quick-search-form {
	width: 400px;
	padding: 30px;
	box-sizing: border-box;
	background-color: var(--content-default);
	color: var(--content-inverted);
	border-radius: 5px 0 0 5px;
	position: absolute;
	right: -400px;
	top: 50px;
	z-index: 4;
	box-shadow: 0 0 8px rgba(0, 0, 0, 0.5);
	transition: all 0.2s ease;
}

.close-quick-search {
	position: absolute;
	right: 30px;
	top: 40px;
	cursor: pointer;
	z-index: 1;
	background: url("../images/cancel-white.svg") no-repeat;
	text-indent: -999px;
	display: block;
	width: 20px;
	height: 20px;
	overflow: hidden;
}

#sidesearch li {
	margin-bottom: 20px;
}

#sidesearch li:last-child {
	margin-bottom: 0px;
}

.sidesearch-title {
	color: var(--content-inverted);
	position: relative;
	padding-bottom: 7px;
	margin-bottom: 30px;
}

.sidesearch-title::before {
	content: '';
	position: absolute;
	left: 0;
	bottom: 0;
	width: 100px;
	height: 2px;
	background-color: var(--content-highlight);
}

.sidesearch-filter-list li.ss_price_from {
	width: 47%;
	float: left;
}

.sidesearch-filter-list li.ss_price_to {
	width: 47%;
	float: right;
}

/*************************************
 * Property Search Form Styles
 *************************************/
 .search-form-container {
	position: relative;
	margin-top: 20px;
}

.search-form-container .search_range {
	font-weight: 600;
}

.search-form-container .search_columns > .row {
	gap: 0;
}
.search-form-container .col-lg-6 {
	width: 32%;
	margin: 0 20px;
	border-right: 1px solid var(--border);
	padding: 0 20px 20px 0;
	float: left;
}

.search-form-container .col-lg-6:nth-child(2) {
	margin-left: 0;
}

.search-form-container ol.column-three {
	padding-right: 0;
	padding-top: 10px;
	clear: both;
    border-top: 1px solid var(--border);
    width: 70.5%;
}

.search-form-container input[type="text"], .search-form-container select {
	border: 1px solid var(--border);
}

.search-form-container .form-group {
	margin: 0 0 20px;
}

.search-form-container .search_built  {
	overflow: hidden;
}

.search-form-container ol.search_mlsid {
	padding-right: 0;
	margin-right: 0;
	border-right: 0;
}

.search-form-container input[type="text"], .search-form-container select {
	width: 100%;
	padding: 10px 5px;
}

.search-form-container select[multiple] {
	width: 100% !important;
}

.search-form-container .search_built select:first-of-type {
	margin: 0 13px 5px 0;
	width: 48%;
	float: left;
}

.search-form-container  .search_built select:last-of-type {
	width: 48%;
}

.search-form-container .searchprice input[type="text"] {
	width: 42%;
}

.search-form-container .the_search.upper_bttn {
	position: absolute;
	right: 20px;
	top: 0;
}

.search-form-container .the_search.lower_bttn {
	position: absolute;
	bottom: 20px;
	right: 20px;
}

/*************************************
 * Agents Page
 *************************************/
.agent_listings_header {
	text-align: left;
}

.agent_listings_header .agent_designations {
	font-weight: bold;
}

.agentlist {
	text-align: left;
	overflow: visible;
}

.brokers_list .agentlist {
	margin-bottom: 60px;
}

.brokers_list .agentlist li {
	box-shadow: 0px 0px 4px rgb(0 0 0 / 20%);
	margin: 0;
	background-color: var(--content-default);
	width: 220px;
	border-radius: 5px;
	overflow: hidden;
}

.brokers_list .agentlist a {
	color: var(--content-inverted);
}

.agentlist ul {
	display: flex;
	flex-wrap: wrap;
	gap: 30px;
}

.agentlist li img {
	max-height: 250px;
	object-fit: cover;
	object-position: top;
	margin-bottom: 5px;
}

.agentlist li a > div {
	width: 90%;
	margin: 0 auto;
}

.agentlist li a > div.agent_level {
	margin-bottom: 8px;
}

.agentpage ul {
	margin-right: 25px;
}

.board_list {
	margin-top: 20px;
}

.pagebody .the_contact_form li {
	margin-bottom: 10px;
}

.pagebody .agent_image img {
	margin-bottom: 20px;
}

/*************************************
 * Refine Search Form
 *************************************/
.refine_search {
	background-color: var(--content-default);
	width: 400px;
	padding: 30px;
	box-sizing: border-box;
	color: var(--content-inverted);
	border-radius: 5px;
	box-shadow: 0 0 4px rgba(0, 0, 0, 16%);
	margin-bottom: 30px;
}

.refine-title{
	color: var(--content-inverted);
	position: relative;
	padding-bottom: 7px;
	margin-bottom: 30px;
}

.refine-title:before {
	content: '';
	position: absolute;
	left: 0;
	bottom: 0;
	width: 100px;
	height: 2px;
	background-color: var(--content-highlight);
}

.refine_search form li {
	margin-bottom: 20px;
}

.refine_search select,
.refine_search input[type="text"] {
	width: 100%;
}

.refine_search .refine-price-from {
	display: inline-block;
	margin-bottom: 0;
	width: 47%;
}

.refine_search .refine-price-to {
	float: right;
	width: 47%;
}

.refine_search li.refine_submit {
	margin-bottom: 0;
}

.search-results {
	display: flex;
	gap: 30px;
}

/*************************************
 * Agent Page
 *************************************/
.agent-page .pagebody {
	display: grid;
	gap: 0px 0px;
	grid-template-columns: auto 400px;
  	grid-template-areas:
    "page-title page-title"
    "agentpage the_contact_form"
    "agent-testimonial-container agent-testimonial-container"
	"agentlist agentlist";
}

.agent-page .page-title {
	grid-area: page-title;
	margin-bottom: 25px;
}

.agent-page .agentpage {
	grid-area: agentpage;
	border-bottom: 0;
}

.agentpage img {
	border: 1px solid var(--border);
}

.agent-page .agent-testimonial-container {
	grid-area: agent-testimonial-container;
	margin-bottom: 30px;
}

.agent-page .agentlist {
	grid-area: agentlist;
}

.agent-page .the_contact_form {
	grid-area: the_contact_form;
	/* background-color: transparent;
	padding: 0; */
}

/*************************************
 * Search Results Page
 *************************************/
.searchitems {
    display: flex;
	background: var(--grey-bg);
	border-radius: 5px;
	padding: 20px;
	margin-bottom: 30px;
}

.searchitems strong {
	display: inline-block;
	margin-right: 10px;
}

/*************************************
 * Pagination
 *************************************/
#upper-pagination_container {
	float: left;
}

#upper-pagination_container .numeric_pagination {
	margin: 0 0 20px;
}

.numeric_pagination ol {
	border-left: 0;
}

.numeric_pagination li {
    border-right: 0;
    padding: 0;
    margin: 0 5px;
}

.numeric_pagination a, .numeric_pagination li.bold, .numeric_pagination li.ellipsis {
	background: var(--grey-bg);
	padding: 6px 12px;
	box-sizing: border-box;
	display: block;
	border-radius: 5px;
	border: 0;
	font-size: 1.8rem;
}

.pagination-links-container .page_returns_select, .pagination-links-container .page_select {
    display: inline-block;
    width: 60px;
}

.numeric_pagination .next_container {
    margin-left: 0;
}

.numeric_pagination .prev_container {
	margin-right: 0;
}

#lower-pagination_container {
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	margin-bottom: 30px;
	margin-top: 30px;
}

#lower-pagination_container .page_links_container {
    margin-top: 0;
}

.results_sort {
	margin: 0;
}

.sort_select {
	margin-bottom: 20px;
}

/*************************************
 * Flags
*************************************/
.new-construction, .alt_new-construction, .bidx_new-construction, .menulog, .like-flag, .pricedrop, .alt_pricedrop, .green, .alt_green, .adult, .alt_adult, .virtualtour, .alt_virtualtour, .foreclosure, .alt_foreclosure, .shortsale, .alt_shortsale, .bidx_pricedrop, .bidx_green, .bidx_adult, .bidx_virtualtour, .bidx_foreclosure, .bidx_shortsale, .view_similar, .alt_view_similar, .videolink
{
	background-size: 100% !important;
	height: 25px;
	width: 25px;
}
.pricedrop, .alt_pricedrop, .bidx_pricedrop {
	background-image: url('../images/flags/reduced_icon.svg');
	background-size: 22px 25px !important;
}
.new-construction, .alt_new-construction, .bidx_new-construction {
	background-image: url('../images/flags/new-construction.svg');
}
.green, .alt_green, .bidx_green {
	background-image: url('../images/flags/green.svg');
}
.adult, .alt_adult, .bidx_adult {
	background-image: url('../images/flags/adult.svg');
}
.virtualtour, .alt_virtualtour, .bidx_virtualtour {
	background-image: url('../images/flags/virtualtour.svg');
}
.foreclosure, .alt_foreclosure, .bidx_foreclosure {
	background-image: url('../images/flags/foreclosure.svg');
}
.shortsale, .alt_shortsale, .bidx_shortsale {
	background-image: url('../images/flags/shortsale.svg');
}
.videolink {
	background-image: url('../images/flags/videolink.svg');
}
.view_similar, .alt_view_similar {
	background-image: url('../images/flags/view_similar.svg');
}

/*************************************
 * Mortgage Calculator
*************************************/
.mtg_input_form {
	background-color: var(--content-default);
	color: var(--content-inverted);
	border-radius: 5px;
	padding: 30px;
	box-shadow: 0 0 4px rgba(0, 0, 0, 16%);
}

.mtg_output_form {
	padding: 30px;
}

.mtg_input_form .mtg_title {
	color: var(--content-inverted);
}

.mtg_input label {
	width: 120px;
}

.mtg_input li {
	margin-bottom: 20px;
}

.mtg_output li {
	margin-bottom: 10px;
}

.mtg_input li.submit {
	margin-bottom: 0;
}

.mtg_title {
	padding-left: 0;
}

.mtg_form {
	display: flex;
	gap: 40px;
}

/*************************************
 * CMA Form
*************************************/
.cma_form li {
	margin-bottom: 20px;
}

.cma_form_container .cma_form:last-of-type label {
	width: 250px;
}

.cma_form_container .cma_form:first-of-type label {
	width: 120px;
}

.cma_form_container input[type="text"],
.cma_form_container input[type="email"],
.cma_form_container input[type="tel"],
.cma_form_container select {
	width: 230px;
}

.cma_form_container .comment_title {
	color: var(--heading-color);
	font-family: var(--heading-font);
	font-size: var(--h2-font-size);
}

/*************************************
 * Poll List
*************************************/
.polllist .polllist_row:hover {
    background: var(--content-highlight);
}

.poll_sub select {
	max-width: 200px;
}

.share-url .social {
	float: none;
	margin-bottom: 20px;
}

.poll-container {
	margin-top: 20px;
}

.share-link-input input {
	width: 100%;
	max-width: 700px;
}

.share-url ul.social-share-list img {
	display: none;
}

.share-url .social-share-list li a {
	width: 25px;
	height: 25px;
	display: block;
}

.share-url .social-share-list li.share-facebook a {
	background: var(--icon-facebook-dark) no-repeat 0 0;
}

.share-url .social-share-list li.share-linkedin a {
	background: url(../images/linkedin-dark.svg) no-repeat 0 0;
}

.share-url .social-share-list li.share-reddit a {
	background: url(../images/reddit-dark.svg) no-repeat 0 0;
}

.share-url .social-share-list li.share-twitter a {
	background: var(--icon-twitter-dark) no-repeat 0 0;
}

/*************************************
 * Sell Your Home Popup
*************************************/
.frontend-customer-dashboard-add-listing-dialog {
    background-color: var(--content-default) !important;
	border-radius: 5PX;
}

.customform-data-container h2 {
	color: var(--content-inverted);
}

.customform-data-container .subhead_title h2 {
	color: var(--content-default);
}

.custom-tabs .tab-content {
    border: 2px solid var(--border) !important;
	padding: 5px 5px 10px !important;
}

.frontend-customer-dashboard-add-listing-dialog .customform-data-container select {
	margin-bottom: 15px !important;
}

.customform-data-container ol {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
}

.widget-edit-form .width-half {
    flex: 0 0 49%;
}

/*************************************
 * Login / Register - Popups
 *************************************/
.popupform {
	background: var(--content-default);
	border-radius: 5px;
	font-family: var(--content-font);
}

.popupform a, #register-popup a, #recover-popup a {
	color: var(--content-inverted) !important;
	font-size: 14px;
}

.popupform .field_msg {
	color: var(--content-inverted);
}

.login_link {
	font-size: 14px;
}

.popupform input[type="text"],
.popupform input[type="password"],
.popupform input[type="email"],
.popupform input[type="tel"] {
	background: var(--content-inverted);
	border: 1px solid var(--border);
	color: var(--content-default);
}

.popupform input[type="text"]:focus,
.popupform input[type="password"]:focus,
.popupform input[type="email"]:focus,
.popupform input[type="tel"]:focus {
	box-shadow: none;
}

.popupform input[type="submit"] {
	background: var(--content-button-bg);
	color: var(--content-button-text);
	border-radius: 5px !important;
	box-shadow: none;
}

.popupform input[type="submit"]:hover {
	background: var(--content-button-hover-bg);
	color: var(--content-button-hover-text);
}

.popupform a.menu_registration {
	background: none;
	border: none;
	box-shadow: none;
	text-decoration: underline;
	color: var(--content-inverted);
}

.popupform a.menu_registration:hover {
	background: none;
	border: none;
	color: var(--content-inverted) !important;
}

.popHead {
	font-size: 1.8rem;
	border-bottom: 1px solid var(--content-inverted);
}

.password_form_container li {
	margin-bottom: 20px;
}

.widget_listings > .widget-inner > div li,
.widget_create_poll > .widget-inner > div li,
.widget_create_testimonial > .widget-inner > div li,
.widget_fsbo_pocket_listings > .widget-inner > div li {
    padding: 5px 20px 5px 10px;
	list-style: none;
}

#maincontent .customer-dashboard-anchor {
	float: none;
    overflow: hidden;
	margin-bottom: 15px;
}

#conmaincontenttent .account-dashboard {
	display: flex;
    flex-wrap: wrap;
    width: 100%;
    justify-content: space-between;
    margin-bottom: 60px;
}

#maincontent .widget {
	float: none;
	display: inline-block;
}

.tab-page form {
	margin-bottom: 60px;
}

.tab-page.docuploadernew.hasdocuments form {
	margin-bottom: 0;
}

.drag-drop-area label {
	color: var(--content-default);
}

.list_img_div .edit_icon input.childChk {
    margin-top: -41px !important;
    left: 20px;
}

.sidesearch-filter-list .home-page-list-group {
	display: none;
	position: absolute;
	width: 84%;
	height: 190px;
}

.sidesearch-filter-list .home-page-list-group .home-page-list-group-item {
	padding: 5px !important;
	font-size: 11px;
	width: 100% !important;
	margin: 0 auto !important;
}

.sidesearch-filter-list .home-page-list-group .home-page-list-group-item:hover {
	background-color: #ddd;
}
