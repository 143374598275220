
.prop.co_commn {
    float: right;
    width: 74%;
  }
@media screen and (max-width: 1279px) {
    .container {
        width: 100%;
    }

    .nav-list li.nav-wrap:hover > ul {
        right: 0;
        left: auto;
    }

    .card-view .alt_property_container {
        max-width: calc(50% - 20px);
    }

    .subdivisionlist ol {
        width: 90%;
    }

    .results_sort {
        float: none;
        text-align: center;
    }

    #upper-pagination_container {
        float: none;
        width: 100%;
        display: flex;
        justify-content: center;
    }

    /* Detail Page */
    .action-buttons {
        justify-content: center;
        flex-wrap: wrap;
        padding-bottom: 0;
    }

    .action-buttons > div {
        margin: 0 10px 20px;
    }

    .calculator_main_sec .field-section-one {
        flex-direction: column;
    }

    .calculator_main_sec .field-section-one .width-50 {
        width: 100%;
    }

    .search_results .alt_property_container {
        width: auto;
        flex: 0 0 47%;
    }

    .prop.co_commn {
        float: right;
        width: 68%;
    }
}

@media screen and (max-width: 1140px) {
    h1, h2, h3, h4, h5, h6, .menu-title, .subdivision-list-title, .condominium-list-title, .fplist-title, .search-title, .featured_listings_header, .detail-image-title, .detail-streetview-title, .detail-features-title, .detail-comment-title, .detail-mapview-title, .detail-column-title, .detail-contact-title, .detail-social-title, .subhead_agentlist, .testimonial_subtitle, .agent_listings_header, .notice .subhead, .mtg_title, .liked-list-title, .refine_search .notice_title, .notice_title, .refine-title,.buyer_agent_comm_title {
        font-size: 2.0rem;
    }

    .pre-header .container {
        align-items: flex-end;
        flex-direction: column;
    }

    .card-view .alt_address a {
        font-size: 1.6rem;
    }

    .col-small,
    .col-small #detailform {
        width: auto;
    }
}

@media screen and (max-width: 980px) {
    .pre-header ol {
        flex-wrap: wrap;
        justify-content: flex-end;
        margin-bottom: 10px;
    }

    .card-view .alt_property_container,
    .search-form-container .search_mlsid {
        max-width: 100%;
    }

    .card-view .alt_property_container:nth-child(even) {
        margin-right: 0px;
    }

    .card-view .alt_propimage a:after {
        width: 300px;
    }

    .pre-footer .container {
        flex-wrap: wrap;
        justify-content: space-between;
    }

    .pre-footer .one-third {
        flex: 0 0 49%;
    }

    .search-results {
        flex-wrap: wrap;
    }

    .featured_listings {
        flex-wrap: wrap;
        justify-content: center;
    }

    .featuredprop {
        margin-left: 10px;
        margin-right: 10px;
    }

    .subdivisionlist ol {
        width: 100%;
        margin-bottom: 25px;
    }

    .pagebody #fb_login_form label {
        width: 35%;
    }

    .pagebody #login-form input[type="text"], .pagebody #login-form input[type="password"] {
        width: 45%;
    }

    .testimonials-page-request {
        flex-wrap: wrap;
        justify-content: center;
    }

    .testimonials-page-request .testimonial-form-link {
        margin-top: 8px;
    }

    .search-form-container .col-lg-6 {
        width: 100%;
        margin: 0;
        border-right: 0;
        padding: 40px 20px 20px 0;
        float: none;
    }

    .search-form-container ol.column-three {
        width: 100%;
    }

    .search-form-container .the_search.lower_bttn {
        position: relative;
        bottom: 0;
        right: 0;
    }

    .search-form-container .col-lg-6:nth-child(2) {
        padding-right: 0;
    }

    .agent-page .pagebody {
        display: block;
    }

    .mtg_form {
        gap: 0;
        flex-direction: column;
    }

    .mtg_output_form {
        padding: 30px 0;
    }

    .poll-listings-container .poll-listing-container,
    .saved_listings_container .alt_property_container {
        flex: 0 0 47%;
    }

    .information-container ol {
        flex-wrap: wrap;
    }

    /* Detail Page */
    .row {
        flex-direction: column;
    }

    .col-small {
        width: 100%;
    }

    .similar-subdivision-list {
        margin-bottom: 50px;
    }

    .col-small #detailform {
        width: 400px;
    }

    .create_poll_listing_container {
        width: 47%;
    }

    main.detail-alt .col-small {
        top: 0;
        margin-top: 20px;
    }

    .features-list {
        margin: 40px 0 0;
    }
}

@media screen and (max-width: 768px) {
    #homepage-banner .headingrelative {
        padding: 0 20px;
    }

    .banner-heading h1 {
        font-size: 3.5rem;
    }

    .banner-menu {
        max-width: 90%;
    }

    .card-view .alt_property_container {
        height: auto;
        box-shadow: 0px 0px 8px rgb(0 0 0 / 40%);
    }

    .card-view .alt_property {
        flex-direction: column;
    }

    .card-view .alt_price {
        bottom: auto;
        top: 140px;
    }

    .card-view .alt_contact {
        bottom: auto;
        top: 165px;
    }

    .card-view .more_info_container, .card-view .like-flag, .card-view .alt_price, .card-view .alt_contact, .card-view .alt_propimage a:after {
        opacity: 1;
    }

    .information-container ol {
        flex-wrap: wrap;
    }

    .contactform {
        flex-direction: column;
    }

    .card-view .alt_propimage a:after,
    .card-view .alt_propimage, .card-view .right_side,
    .card-view .alt_propimage img,
    .pagebody #login-form input[type="text"],
    .pagebody #login-form input[type="password"] {
        width: 100%;
    }

    .pre-footer .one-third,
    .poll-listings-container .poll-listing-container,
    .saved_listings_container .alt_property_container {
        flex: 0 0 100%;
    }

    footer .container {
        flex-direction: column;
    }

    .footer-agentinfo-block {
        width: 100%;
        padding: 20px;
    }

    footer .spacer {
        padding: 15px;
    }

    .pagebody #fb_login_form label {
        width: 100%;
        margin-bottom: 5px;
    }

    .search-form-container .the_search.upper_bttn {
        position: relative;
        right: 0;
    }

    .search-form-container .col-lg-6 {
        padding: 0px;
    }

    .search-form-container .search_columns > .row {
        flex-wrap: wrap;
    }

    .search-results {
        gap: 0;
        flex-wrap: wrap;
    }

    .testimonial_form {
        max-width: 100%;
        box-sizing: border-box;
    }

    .sd_addressbar {
        margin-bottom: 0;
    }

    .propimage {
        width: 100%;
        max-width: 100%;
        margin-right: 0;
    }

    .propholder .col2,
    .propholder .col1 {
        width: 46%;
        margin: 0 2% 20px;
    }

    .agentpage img.agentimage {
        float: none;
    }

    .agentpage img {
        margin-right: 10px;
    }

    .agentpage ul {
        margin-right: 0;
        width: 100%;
    }

    .cma_form_container li {
        align-items: flex-start;
        flex-direction: column;
    }

    .cma_form_container input[type="text"],
    .cma_form_container input[type="email"],
    .cma_form_container input[type="tel"],
    .cma_form_container select {
        width: 100%;
    }

    .pagebody .agent_image {
        width: 100%;
        display: block;
        overflow: hidden;
        margin-bottom: 20px;
    }

    /* Detail Page */
    .seller-popup-form {
        position: relative;
    }

    .bidx_printbutton {
        position: relative;
        margin-bottom: 10px;
    }

    .calculator_main_sec .field-section-two {
        flex-wrap: wrap;
        gap: 0;
    }

    .field-section-two form,
    .field-section-two > .width-50 {
        width: 100%;
    }

    .widget {
        width: 100%;
        margin: 0 0 20px 0;
    }

    .customform-data-container.widget-edit-form .documents-container {
        width: 100%;
    }

    .widget-edit-form .display_inline_block .width-half {
        width: 100%;
        margin: 3px 0;
    }

    .customform-data-container {
        margin-bottom: 20px;
    }

    .widget-edit-form .display_inline_block li.width-half.input-radio {
        min-height: auto;
    }

    .widget-edit-form .display_inline_block .input-radio div {
        align-items: baseline;
        width: 50%;
    }

    .search_results .alt_property_container {
        flex: 0 0 100%;
    }

    .propimage img {
        max-height: 250px;
        object-fit: cover;
    }

    .tab-headings {
        overflow-x: scroll;
    }

    .tab-headings a {
        padding: 8px 10px;
        font-size: 1.4rem;
    }
}

@media screen and (max-width: 576px) {
    .banner-heading h1 {
        font-size: 3.0rem;
    }

    .banner-menu-items a {
        margin: 5px 15px;
    }

    .banner-searchbar {
        margin-top: 15px;
    }

    .numeric_pagination {
        flex-wrap: wrap;
        justify-content: center;
    }

    .numeric_pagination ol {
        align-items: flex-start;
    }

    .numeric_pagination a {
        margin-bottom: 8px;
    }

    .brokers_list .agentlist li {
        width: 190px;
    }

    .searchitems {
        flex-wrap: wrap;
    }

    .searchitems > strong {
        margin-bottom: 5px;
    }

    .brokeridxlink a {
        display: flex;
        margin-right: 0;
        justify-content: space-evenly;
    }

    .brokeridxlink,
    .switch-to-mobile-container {
        text-align: center;
        float: none;
    }

    #detailprop {
        flex-direction: column;
        gap: 0;
    }

    .action-buttons > div {
        margin: 0 10px 10px;
    }

    .widget-edit-form .display_inline_block .input-radio div {
        width: 100%;
    }
}

@media screen and (max-width: 480px) {
    .banner-heading p {
        font-size: 1.7rem;
    }

    .banner-menu-items a {
        margin: 5px 10px;
    }

    .card-view .fpmorelink {
        position: relative;
        top: 0;
    }

    .social_link .fbLogin {
        width: 100%;
        background-size: contain;
    }

    .search-form-container .search_private {
        flex-direction: row;
    }

    .promo_form, #contact_form,
    .the_contact_form,
    .the_contact_form.pop-up.contact_form,
    .contact_form, .detailform.pop-up,
    .detail-submit-offer-popup {
        width: auto;
    }

    .promo_form .form-request-elements li.form-first-name,
    #contact_form .form-request-elements li.form-first-name,
    .the_contact_form .form-request-elements li.form-first-name,
    .contact_form .form-request-elements li.form-first-name,
    .promo_form .form-request-elements li.form-last-name,
    #contact_form .form-request-elements li.form-last-name,
    .the_contact_form .form-request-elements li.form-last-name,
    .contact_form .form-request-elements li.form-last-name {
        width: 100%;
    }

    .propholder .col2, .propholder .col1 {
        width: 96%;
        margin: 0 0 20px 4%;
    }

    .calculation_detail li span {
        float: none;
        top: 0;
        display: block;
    }

    .calculation_detail li input {
        width: 94%;
    }

    #pay-cal-form .thedownpayment {
        flex-direction: column;
    }

    #pay-cal-form .thedownpayment .amount,
    #pay-cal-form .thedownpayment .per {
        width: 100%;
    }

    .page_links_container {
        flex-wrap: wrap;
        justify-content: center;
        gap: 10px;
    }

    .refine_search,
    .col-small #detailform {
        width: auto;
    }
}
