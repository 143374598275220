/*************************************
 * Card View
*************************************/
.card-view {
	margin-bottom: 60px;
	position: relative;
}

.card-view .alt_property_container {
	width: 100%;
	max-width: 600px;
	height: 200px;
	border-radius: 5px;
	overflow: hidden;
	box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.2);
	padding: 0;
	float: left;
	margin-bottom: 40px;
	float: left;
}

.card-view .alt_property_container:hover {
	box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.4);
}

.card-view .alt_property_container:nth-child(even) {
	margin-right: 40px;
}

.card-view .alt_property {
	position: relative;
	display: flex;
}

.card-view .agent-designate-icon {
	display: none;
}

.card-view .alt_property .alt_description,
.card-view .alt_property .alt_col2 {
	display: none;
}

.card-view .alt_contact,
.card-view .propflags {
	position: absolute;
	bottom: 20px;
}

.card-view .alt_contact {
	left: 20px;
}

.card-view .alt_contact a {
	color: var(--content-button-text);
	text-decoration: none;
	font-weight: 600;
	font-size: 1.4rem;
	background: var(--content-button-bg);
	padding: 2px 5px;
	border-radius: 3px;
}

.card-view .propflags {
	right: 5px;
}

.card-view .alt_title {
	padding: 0;
	margin-top: 0;
	margin-bottom: 10px;
}

.card-view .alt_address a {
	font-size: 1.8rem;
	font-weight: bold;
	text-decoration: none;
}

.card-view .alt_propimage,
.card-view .right_side {
	width: 50%;
}

.card-view .right_side {
	padding: 15px;
	box-sizing: border-box;
}

.card-view .alt_propimage img {
	display: block;
	height: 200px;
	width: 300px;
	object-fit: cover;
}

.card-view .alt_propimage a {
	position: relative;
	display: block;
}

.card-view .alt_propimage a:after {
	content: '';
	position: absolute;
	width: 100%;
	height: 100%;
	top: 0;
	background-color: rgba(0, 0, 0, 0.5);
}

.card-view .more_info_container {
	position: absolute;
	z-index: 1;
	top: 20px;
	left: 20px;
	font-size: 1.4rem;
	color: var(--content-inverted);
	border-top: 0 !important;
	padding: 0 !important;
}

.card-view .more_info_container a {
	color: var(--content-inverted);
	text-decoration: none;
	font-weight: 600;
	background: url(../images/icon-image-gallery-white.svg) no-repeat left center;
	display: inline-block;
	padding-left: 30px;
	line-height: 2.3rem;
}

.card-view .like-flag {
	position: absolute;
	left: 255px;
	top: 20px;
}

.card-view .like-flag a span {
	width: 26px;
	height: 23px;
}

.card-view .like_login {
	background: url(../images/icon-like-white.svg) no-repeat;
}

.card-view .alt_price {
	position: absolute;
	z-index: 1;
	bottom: 45px;
	left: 20px;
	color: var(--content-inverted);
	font-weight: bold;
}

.card-view .fpmorelink {
	clear: both;
}

.card-view .fp_filter_display_container {
	font-size: var(--content-font-size);
}

.card-view .fp_filter_display_container .refine-search-button {
	margin-top: 10px;
}

.card-view .alt_details {
	width: 100%;
}

.card-view .alt_beds {
	background: url(../images/icon-bed.svg) no-repeat left center;
}

.card-view .alt_baths {
	background: url(../images/icon-bath.svg) no-repeat left center;
}

.card-view .alt_sqft {
	background: url(../images/icon-area.svg) no-repeat left center;
}

.card-view .alt_acreage {
	background: url(../images/icon-area.svg) no-repeat left center;
}

.card-view .alt_details li {
	padding: 6px 0 6px 25px;
	margin-bottom: 0;
}

.card-view .more_info_container,
.card-view .like-flag,
.card-view .alt_price,
.card-view .alt_contact,
.card-view .alt_propimage a:after {
	opacity: 0;
	transition: all 0.2s ease-in;
}

.card-view .alt_property_container:hover .alt_address a {
	color: var(--content-highlight);
}

.card-view .alt_property_container:hover .more_info_container,
.card-view .alt_property_container:hover .like-flag,
.card-view .alt_property_container:hover .alt_price,
.card-view .alt_property_container:hover .alt_contact,
.card-view .alt_property_container:hover .alt_propimage a:after {
	opacity: 1;
}

/*************************************
 * Search Results
*************************************/
.search_results,
.poll-listings-container,
.saved_listings_container {
	display: flex;
	flex-wrap: wrap;
	gap: 30px;
	overflow: visible;
}

.propholder,
.poll-listing-container {
	background-color: var(--grey-bg);
	border-radius: 5px;
	overflow: hidden;
}

.propholder a,
.poll-listing-container a {
	color: var(--content-highlight);
}

.sd_addressbar, .column-title {
	font-size: var(--h2-font-size);
	font-weight: bold;
}

.sd_addressbar {
	margin-bottom: 20px;
	padding: 20px;
	border-bottom: 1px solid var(--border);
}

.column-title {
	margin-bottom: 10px;
}

.propholder .prop {
	margin-bottom: 20px;
}

.propholder .col2 {
	margin-right: 20px;
}

.propholder .col2 li, .propholder .co_commn li{
	margin-bottom: 5px;
	margin-left: 20px;
	list-style: square;
}

.prop.co_commn {
	float: right;
	width: 74%;
}

.co_commn_detail_wrap.single_col .prop.co_commn {
	width: 100%;
}

.propimage {
	margin-right: 20px;
}

.propfooter {
	margin-top: 10px;
	padding-top: 10px;
	border-top: 1px solid var(--border);
	background-color: var(--content-default);
	padding: 20px;
	color: var(--content-inverted);
}

.propfooter a {
	color: var(--content-inverted);
}

.propcomment,
.moreinfo {
	margin-bottom: 10px;
	padding-bottom: 10px;
	border-bottom: 1px solid var(--grey-light);
}

.poll-listings-container .poll-listing-container {
	border: 0;
	padding: 0;
	margin: 0;
	flex: 0 0 31%;
}

.poll-listings-container form {
	width: 100%;
}

/*************************************
 * Alt Property
*************************************/
.search_results .alt_property_container,
.poll-listing-container .alt_property_container,
.saved_listings_container .alt_property_container,
.create_poll_listing_container {
	background-color: var(--grey-bg);
	border-radius: 5px;
	padding: 0;
	margin: 0;
	width: 390px;
	box-sizing: border-box;
	float: left;
	overflow: hidden;
	box-shadow: 0 0 3px rgba(0, 0, 0, 15%);
	position: relative;
}

.create_poll_listing_container .alt_property_container {
	box-shadow: none;
}

.poll-listing-container .alt_property_container {
	width: 100%;
}

.search_results .alt_propimage,
.poll-listing-container .alt_propimage,
.saved_listings_container .alt_propimage {
	width: 100%;
}

.search_results .alt_propimage img,
.poll-listing-container .alt_propimage img,
.saved_listings_container .alt_propimage img {
	height: 250px;
	object-fit: cover;
	display: block;
}

.search_results .alt_price,
.poll-listing-container .alt_price,
.saved_listings_container .alt_price {
	position: absolute;
	top: 15px;
	right: 15px;
	z-index: 1;
	background-color: var(--content-button-bg);
	color: var(--content-button-text);
	font-weight: 600;
	padding: 2px 5px;
	border-radius: 3px;
}

.search_results .alt_property_container .right_side,
.poll-listing-container .alt_property_container .right_side,
.saved_listings_container .alt_property_container .right_side {
	width: auto;
	padding: 20px;
}

.search_results .alt_title,
.poll-listing-container .alt_title,
.saved_listings_container .alt_title {
	padding: 0;
}

.search_results .alt_contact,
.poll-listing-container .alt_contact,
.saved_listings_container .alt_contact {
	float: none;
	font-weight: bold;
	padding-bottom: 20px;
}

.search_results .alt_details,
.poll-listing-container .alt_details,
.saved_listings_container .alt_details {
	display: block;
	width: 100%;
}

.search_results .alt_property_container .like-flag,
.poll-listing-container .alt_property_container .like-flag,
.saved_listings_container .alt_property_container .like-flag {
	position: absolute;
	top: 235px;
	left: calc(50% - 15px);
	background-color: var(--content-inverted);
	padding: 5px;
	background-position: center;
	box-sizing: border-box;
	width: 30px;
	height: 30px;
	border-radius: 50%;
}

.search_results .alt_address,
.poll-listing-container .alt_address,
.saved_listings_container .alt_address {
	font-weight: bold;
}

.alt_address a {
	display: inline-block;
	max-width: 250px;
	font-size: var(--h2-font-size);
	font-family: var(--heading-font);
	color: var(--heading-color);
}

.more_info_container {
	padding: 20px;
	border-top: 1px solid var(--border);
}

.alt_details li {
	margin-bottom: 5px;
}

.like_login, .like-flag a.like_off {
	background: url("../images/icon-like.svg") no-repeat;
	background-size: 20px;
	margin-top: 1px;
}

.like-flag a {
	margin-left: 0;
	cursor: pointer;
	background-size: 20px;
	margin-top: 4px;
}

.alt_property .like-flag a {
	margin-top: 1px;
}

.poll-rating {
	width: 100%;
    padding: 20px;
	border-bottom: 1px solid var(--border);
	box-sizing: border-box;
	background-color: var(--content-default);
	color: var(--content-inverted);
}

.membercount {
    width: 100%;
    display: block;
    padding: 20px;
}

.poll-rating .rate2 {
    line-height: 1;
    height: 30px !important;
}

.poll-rating p {
    margin: 0px 10px 3px 0;
	float: none;
}

.jq-star svg {
    width: 20px;
    height: 20px;
}

.jq-star {
    width: 24px !important;
    height: 24px !important;
}

.create_poll_listing_container {
    position: relative;
}

.checkboxval {
    display: inline-block;
    padding: 4px 5px 2px;
    border: 1px solid var(--content-highlight) !important;
    background: #fff;
    position: absolute;
    top: 15px;
    left: 15px;
    z-index: 2;
	border-radius: 3px;
	line-height: 1;
}

.poll-listing-container, .poll_listing_container, .pollcss .alt_property_container {
    border: 0;
}
