/***********************
 Theme Name: Theme 7
 Author: Hardeep Kumar
 ***********************/

 :root {
	/* variables */
	--content-default: #23334A;
	--content-highlight: #176FB7;
	--content-inverted-bg: var(--content-default);
	--content-inverted: #FFF;
	--content-light-bg: #F0F9FB;
	--content-font-size: 16px;
	--content-font: "Raleway", sans-serif;
	--content-link-color: var(--content-highlight);
	--content-link-hover: var(--content-default);
	--content-button-text: var(--content-inverted);
	--content-button-bg: var(--content-highlight);
	--content-button-hover-text: var(--content-inverted);
	--content-button-hover-bg: var(--content-highlight);

	--heading-font: var(--content-font);
	--heading-color: var(--content-default);
	--h1-font-size: 22px;
	--h2-font-size: 18px;
	--h3-font-size: 16px;

	--banner-heading-color: var(--content-inverted);
	--banner-heading-font-size: 42px;
	--banner-subheading-color: var(--content-inverted);
	--banner-subheading-font-size: 22px;
	--banner-link-color: var(--content-inverted);
	--banner-link-hover: var(--content-inverted);

	--grey: #333;
	--grey-light: #666666;
	--grey-bg: #EFEFEF;
	--border: #ddd;

	/*-- Images --*/
	--icon-facebook-dark: url(../images/facebook-dark.svg);
	--icon-twitter-dark: url(../images/twitter-dark.svg);
	--icon-bluesky-dark: url(../images/bluesky-dark.svg);
	--icon-linktree-dark: url(../images/linktree-dark.svg);
	--icon-share-dark: url(../images/share-dark.svg);
}
