@font-face {
	font-family: 'Raleway';
	src: url('/templates/common/fonts/raleway/Raleway-SemiBold.woff2') format('woff2'),
		 url('/templates/common/fonts/raleway/Raleway-SemiBold.woff') format('woff');
	font-weight: 600;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Raleway';
	src: url('/templates/common/fonts/raleway/Raleway-Bold.woff2') format('woff2'),
		 url('/templates/common/fonts/raleway/Raleway-Bold.woff') format('woff');
	font-weight: bold;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Raleway';
	src: url('/templates/common/fonts/raleway/Raleway-Regular.woff2') format('woff2'),
		 url('/templates/common/fonts/raleway/Raleway-Regular.woff') format('woff');
	font-weight: normal;
	font-style: normal;
	font-display: swap;
}
